import React, { useEffect, useState, useRef } from 'react';

import copyIcon from '../../assets/fi_8860773.svg';
import addIcon from '../../assets/Add.svg';
import dot from '../../assets/Drag_Vertical.svg';
import download from '../../assets/DownloadImg.svg';
import chevronLeft from '../../assets/Chevron_left.svg';
import dragVertical from '../../assets/Drag_Vertival.svg';
import continue_arrow from '../../assets/contiune_arrow.svg';
import "../Documents/documentDetail.css";
import KeywordsListCard from './KeywordsListCard';
import KeywordModal from './KeywordModal';
import SerpCard from './SerpCard';
import SerpModal from './SerpModal';
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import { countWords, copyToClipboard } from "../../utils/utils";
import useStore from "../../Store";
import { toast } from 'react-toastify';
import { fetchFaqs, fetchTitles, fetchSeoScore, fetchSummary, fetchSubheading, fetchSerpData, fetchKeypoints, fetchIntroduction, fetchImages, calculateAverageSeoScore } from '../../utils/documentFunction';
import Builder from './Builder';
import DocumentSection from './DocumentSection';
import ContentEditable from 'react-contenteditable';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


const DocumentDetailContent = ({ doc }) => {
    const { secondaryKeywords, docContent, setDocContent, clearDocContent, setDocSave } = useStore()
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [display, setDisplay] = useState(true);
    const [subheading, setSubHeading] = useState(true);
    const [noSubHeading, setNoSubHeading] = useState(false);
    const [noIntros, setNoIntros] = useState(false);
    const [noFaqs, setNoFaqs] = useState(false);
    const [nokeyPoints, setNoKeyPoints] = useState(false);
    const [noSummary, setNoSummary] = useState(false);
    const [intro, setIntro] = useState(true);
    const [faq, setFaq] = useState(true);
    const [keyPoint, setKeyPoint] = useState(true);
    const [summary, setSummary] = useState(true);
    const [image, setImage] = useState(true);
    const [openSerpModal, setOpenSerpModal] = useState(false);
    const [description, setDescription] = useState('');
    const [activeTab, setActiveTab] = useState('Element');
    const [activeContent, setActiveContent] = useState('Element');
    const [titles, setTitles] = useState([]);
    const [subHeadings, setSubHeadings] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [intros, setIntros] = useState([]);
    const [keyPoints, setKeyPoints] = useState([]);
    const [summarys, setSummarys] = useState([]);
    const [seoScore, setSeoScore] = useState({});
    const [serpList, setSerpList] = useState({});
    const [images, setImages] = useState({});
    const [saveContent, setSaveContent] = useState({});
    const isDisabled = !title || !description;
    const [metaDescription, setMetaDescription] = useState()
    const [bar, setBar] = useState(0)
    const [loader, setLoader] = useState(false)
    const [addKeyword, setAddKeyword] = useState(false);

    const [numberOfImages, setNumberOfImages] = useState(0)
    const [tagName, setTagName] = useState("")
    const HandleMetaDescription = (event) => {
        const updatedDocContent = { ...docContent };

        updatedDocContent.metaDescription = event.target.value
        setMetaDescription(event.target.value);
        setDocContent(updatedDocContent);
        setDocSave(true)
    }

    const titleBoxRef = useRef(null);
    const descriptionBoxRef = useRef(null);
    const handleTitle = (e) => {
        const newTitle = e.target.value;
        let titeSet = ''
        if (newTitle) {
            if (!newTitle.includes('<h1>')) {
                titeSet = `<h1>${newTitle} </h1>`;
            }
            else if (newTitle === '<h1><br> </h1>') {
                titeSet = ''
            }
            else {
                titeSet = newTitle
            }
            setTitle(titeSet);
            const updatedDocContent = { ...docContent };
            updatedDocContent.title = titeSet
            setDocContent(updatedDocContent);
        } else {

            setTitle('');
            setDocContent({ title: '' });
        }
        setDocSave(true)

    };



    const handleDisplay = () => {
        setDisplay(!display);
    };

    const handleDragStart = (data, type) => (e) => {
        if (data) {

            if (type === "faq") {
                e.dataTransfer.setData("text/plain", JSON.stringify({ question: data.question, id: data.id, answer: data.answer, type: type }));
            }

            else if (type === "summary") {
                e.dataTransfer.setData("text/plain", JSON.stringify({ text: data, id: data.id, type: type }));
            }
            else if (type === "keypoint") {
                e.dataTransfer.setData("text/plain", JSON.stringify({ title: data.title, description: data.description, id: title.id, type: type }));
            }

            else if (type === "subheading") {
                e.dataTransfer.setData("text/plain", JSON.stringify({ text: data.text, tag: data.headingLevel, id: data.id, type: type }));
            }

            else if (type === "image") {
                e.dataTransfer.setData("text/plain", JSON.stringify({ type: 'image', src: data.url, width: data.width, height: data.height, alt: data.alt }));
            }
            else if (type === "title") {
                e.dataTransfer.setData("text/plain", JSON.stringify({ text: data.text, id: title.id, type: type, count: title.count }));
            }

            else {
                e.dataTransfer.setData("text/plain", JSON.stringify({ text: data.text, id: data.id, type: type }));
            }
            console.log("title", title)
        }

    };
    const [oldValue, setOldValue] = useState({})

    const handleDrop = (e) => {
        e.preventDefault();

        let rawData;
        try {
            rawData = e.dataTransfer.getData("text/plain");
            const data = JSON.parse(rawData);

            if (!data || !data.type) {
                console.warn("Dropped data is invalid:", rawData);
                return;
            }

            const { clientX, clientY } = e;
            const titleBox = titleBoxRef.current;
            const descriptionBox = descriptionBoxRef.current;

            const titleRect = titleBox.getBoundingClientRect();
            const descriptionRect = descriptionBox.getBoundingClientRect();

            if (data.type === 'title') {
                if (clientX >= titleRect.left && clientX <= titleRect.right && clientY >= titleRect.top && clientY <= titleRect.bottom) {
                    const newTitle = title ? `${data.text}` : data.text;
                    let newTitleState = "";
                    let newDocContent = { ...docContent };
                    newTitleState = `<h1>${newTitle}</h1>`;
                    newDocContent.title = newTitleState;
                    setTitle(newTitleState)
                    setDocContent(newDocContent);
                    let updatedTitles = [...titles];

                    if (oldValue && Object.keys(oldValue).length > 0) {
                        updatedTitles = updatedTitles.filter(title => title.text !== data.text);
                        if (!updatedTitles.includes(oldValue)) {
                            updatedTitles.push(oldValue);
                        }
                        setOldValue({ id: data.id, text: data.text, count: data.count })

                    } else {
                        updatedTitles = updatedTitles.filter(title => title.text !== data.text);
                        setOldValue({ id: data.id, text: data.text, count: data.count })

                    }


                    setTitles(updatedTitles);
                    setDocSave(true)



                }
            } else if (clientX >= descriptionRect.left && clientX <= descriptionRect.right && clientY >= descriptionRect.top && clientY <= descriptionRect.bottom) {
                let newDescription = description || "";
                let newDocContent = docContent;
                newDocContent.description = newDocContent.description || ""
                if (title) {
                    switch (data.type) {
                        case 'faq':
                            newDescription += `<p>${data.question || ""}</p><p>${data.answer || ""}</p>`;
                            newDocContent.description += `<li>${data.question || ""}</li><p>${data.answer || ""}</p>`;
                            break;

                        case 'keypoint':
                            newDescription += `<p>•${data.title} <p>${data.description}</p></p>`;
                            newDocContent.description += `<li>${data.title} <p>${data.description}</p></li>`;
                            break;

                        case 'subheading':
                            newDescription += `<${data.tag.toLowerCase()}>${data.text || ""}</${data.tag.toLowerCase()}>`;
                            newDocContent.description += `<${data.tag.toLowerCase()}>${data.text}</${data.tag.toLowerCase()}>`;
                            break;

                        case 'image':
                            newDescription += `<img src="${data.src}" width=${data.width} height=${data.height} alt="${data.alt}" />`;
                            newDocContent.description += `<img src="${data.src}" width:${data.width} height:${data.height} alt="${data.alt} "/>`;
                            break;


                        default:
                            newDescription += `<p>${data.text || ""}</p>`;
                            newDocContent.description += `<p>${data.text}</p>`;
                            break;
                    }

                    setDescription(newDescription);
                    setDocContent(newDocContent);
                    setDocSave(true)
                } else {
                    toast.error("Add Title first")
                }
            }

        } catch (error) {

        }
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        setSaveContent(doc)
        setData()
    }, [doc])

    const setData = () => {
        if (doc) {
            setTitle(doc.title || "")
            setDescription(doc.description || "")
            setDocContent(doc)
            setMetaDescription(doc.metaDescription || null)

        }
    }



    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setActiveContent(tab)
        if (tab === "Element") {
            setDisplay(true)
            setSubHeading(true)
            setIntro(true)
            setFaq(true)
            setKeyPoint(true)
            setSummary(true)
            setImage(true)
        }
    };

    const handleSerpModal = () => {
        setOpenSerpModal(true)
    }

    const [documentKeywords, setDocumentKeywords] = useState([]);

    const handleKeywordSelect = (name) => {
        setDocumentKeywords((prevSelected) => {
            if (prevSelected.includes(name)) {
                return prevSelected.filter((keyword) => keyword !== name);
            } else {
                return [...prevSelected, name];
            }
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const titles = await fetchTitles(id);
            setTitles(titles)
            const serpData = await fetchSerpData(id);
            setSerpList(serpData)
            setBar(calculateAverageSeoScore(serpData))
            const fetchedImages = await fetchImages(id);
            setImages(fetchedImages)
        }
        fetchData()
        clearDocContent()
    }, []);


    useEffect(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(description, 'text/html');
        const images = doc.querySelectorAll('img');
        setNumberOfImages(images.length)
    }, [description]);

    const generateTitles = async () => {
        setLoader(true);
        const titles = await fetchTitles(id);
        setLoader(false);
        if (titles) {
            setTitles(prevTitles => [...prevTitles, ...titles]);
        }
    };

    const generateIntros = async () => {
        setLoader(true);
        const intros = await fetchIntroduction(id);
        setLoader(false);
        if (intros) {
            setIntros(prevIntros => [...prevIntros, ...intros]);
        }
    };

    useEffect(() => {
        const fetchSeo = async () => {
            if (doc) {
                const seoScore = await fetchSeoScore(id, doc?.description ? doc.description : "");
                setSeoScore(seoScore || 0)
            }
        }
        fetchSeo()
    }, [doc]);


    const generateSubheading = async () => {
        setLoader(true);
        const subHeadings = await fetchSubheading(id);
        setLoader(false);
        if (subHeadings) {
            setSubHeadings(prevSubHeadings => [...prevSubHeadings, ...subHeadings]);
        }
    };

    const generateFaqs = async () => {
        setLoader(true);
        const faqs = await fetchFaqs(id);
        setLoader(false);
        if (faqs) {
            setFaqs(prevFaqs => [...prevFaqs, ...faqs]);
        }
    };

    const generateKeypoints = async () => {
        setLoader(true);
        const keypoints = await fetchKeypoints(id);
        setLoader(false);
        if (keypoints) {
            setKeyPoints(prevKeypoints => [...prevKeypoints, ...keypoints]);
        }
    };

    const generateSummary = async () => {
        setLoader(true);
        const summaries = await fetchSummary(id);
        setLoader(false);
        if (summaries) {
            setSummarys(prevSummaries => [...prevSummaries, ...summaries]);
        }
    };



    const elementSectionRef = useRef(null);
    const scrollToBottom = () => {
        if (elementSectionRef.current) {
            if (loader) {
                const loaderElement = elementSectionRef.current.querySelector('.centered-loader');
                elementSectionRef.current.scrollTo({
                    top: loaderElement.offsetTop + loaderElement.offsetHeight,
                    behavior: 'smooth',
                });
            } else {
                elementSectionRef.current.scrollTo({
                    top: elementSectionRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }
    };

    useEffect(() => {
        scrollToBottom()
    }, [titles, intros, subHeadings, faqs, keyPoints, summarys, loader]);

    const handleOpen = () => {
        setAddKeyword(true)
    }

    const handleSubheading = async () => {
        setLoader(true);

        try {
            const subHeadings = await fetchSubheading(id);

            if (subHeadings.length > 0) {
                setSubHeadings(subHeadings);
            } else {
                setNoSubHeading(true);
            }
            setSubHeading(prev => !prev);
        } catch (error) {
            console.error("Error fetching subheadings:", error);
        } finally {
            setLoader(false);
        }
    };

    const handleIntro = async () => {
        setLoader(true);

        try {
            const fetchedIntros = await fetchIntroduction(id);

            if (fetchedIntros.length > 0) {
                setIntros(fetchedIntros);
            } else {
                setNoIntros(true);
            }
            setIntro(prev => !prev)

        } catch (error) {
            console.error("Error fetching Intros:", error);
        } finally {
            setLoader(false);
        }
    }

    const handleFaq = async () => {
        setLoader(true);

        try {
            const faqs = await fetchFaqs(id);
            if (faqs.length > 0) {
                setFaqs(faqs)
            } else {
                setNoFaqs(true);
            }
            setFaq(!faq)

        } catch (error) {
            console.error("Error fetching faqs:", error);
        } finally {
            setLoader(false);
        }
    }

    const handleKeyPoint = async () => {
        setLoader(true);
        try {
            const fetchedKeyPoints = await fetchKeypoints(id);
            if (fetchedKeyPoints.length > 0) {
                setKeyPoints(fetchedKeyPoints);
            } else {
                setNoKeyPoints(true);
            }

            setKeyPoint(!keyPoint)

        } catch (error) {
            console.error("Error fetching keypoints:", error);
        } finally {
            setLoader(false);
        }
    }
    const handleSummary = async () => {

        setLoader(true);
        try {
            const summaries = await fetchSummary(id);
            if (summaries.length > 0) {
                setSummarys(summaries)
            } else {
                setNoSummary(true);
            }
            setSummary(!summary)

        } catch (error) {
            console.error("Error fetching summaries:", error);
        } finally {
            setLoader(false);
        }
    }

    const handleImage = () => {
        setImage(!image)
    }

    const [activeImagesTab, setActiveImagesTab] = useState('stock');

    const handleTabClick = (tab) => {
        setActiveImagesTab(tab);
    };

    const handleEdit = (event) => {
        const sanitizedValue = event.target.value.replace(/<\/?span[^>]*>/g, "");

        const updatedDocContent = { ...docContent };
        updatedDocContent.description = sanitizedValue;
        setDocContent(updatedDocContent);
        setDescription(sanitizedValue);
        setDocSave(true)
        setContinueWriting(false)
    };

    const handleClick = async () => {
        try {
            const sanitizedTitle = title.replace(/<[^>]*>/g, '');
            const sanitizedDescription = description.replace(/<[^>]*>/g, '');
            if (!sanitizedTitle || !sanitizedDescription) {
                console.warn("Title or description is empty after sanitization.");
                return;
            }

            const updatedDocContent = { ...docContent };

            const response = await api.post(`/document/generate-meta-discription/`, {
                title: sanitizedTitle,
                current_content: sanitizedDescription,
                document_id: id
            });
            if (response.data?.meta_description) {
                const metaDescription = response.data.meta_description;
                updatedDocContent.metaDescription = metaDescription;
                setMetaDescription(metaDescription);
                setDocContent(updatedDocContent);
                setDocSave(true);
            } else {
                console.warn("Meta description not found in the response.");
            }

        } catch (err) {
            console.error("Fetching data failed:", err);
            alert("An error occurred while generating the meta description. Please try again.");
        }
    };


    const HandleSet = async () => {
        const descriptionContent = descriptionBoxRef.current.firstChild?.innerHTML || '';
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = descriptionContent;
        let currentNode = tempDiv.firstChild;
        let accumulatedText = '';
        let pCounter = 0;
        while (currentNode) {
            pCounter += 1;

            if (pCounter - 1 === cursor.paragraphIndex) {
                let previousTag = currentNode.previousSibling;
                while (previousTag) {
                    if (/^H[1-6]$/.test(previousTag.tagName)) {
                        let headingContent = `${previousTag.tagName.toLowerCase()}: ${previousTag.textContent}`;
                        accumulatedText = headingContent + ',' + accumulatedText;
                        accumulatedText += "paragraph:" + currentNode.textContent.slice(0, cursor.offset);
                        break;
                    } else {
                        accumulatedText = `paragraph: ${previousTag.textContent} ` + accumulatedText;
                    }
                    previousTag = previousTag.previousSibling;
                }

                if (!/^H[1-6]$/.test(previousTag?.tagName)) {
                    accumulatedText += "paragraph:" + currentNode.textContent.slice(0, cursor.offset);
                }
            }

            currentNode = currentNode.nextSibling;
        }



        try {
            const response = await api.post(
                `/document/write-more/`,
                {
                    current_content: accumulatedText || '',
                    document_id: id
                },
            );
            console.log("RESULT:     ", response.data)
            const newContent = response.data.result || '';
            let updatedDocContent = { ...docContent };


            const typeContent = (text, speed) => {
                if (!text || typeof text !== 'string') {
                    console.error('Invalid text provided');
                    return;
                }

                let characters = '';
                const paragraphs = descriptionBoxRef.current.firstChild.querySelectorAll('*');
                const newParagraph = document.createElement('p');
                let i = 0;
                if (paragraphs.length > 0) {
                    const intervalId = setInterval(() => {
                        if (i < text.length) {
                            characters += text[i];
                            newParagraph.innerHTML = characters;
                            paragraphs[cursor.paragraphIndex].insertAdjacentElement('afterend', newParagraph);
                            const setText = descriptionBoxRef.current.firstChild.innerHTML;
                            setDescription(setText);
                            i++;
                        } else {
                            clearInterval(intervalId);
                            updatedDocContent.description = descriptionBoxRef.current.firstChild.innerHTML;
                            setDocContent(updatedDocContent);
                            setDocSave(true)

                        }
                    }, speed);
                } else {
                    const intervalId = setInterval(() => {
                        if (i < text.length) {
                            characters += text[i];
                            newParagraph.innerHTML = characters;
                            descriptionBoxRef.current.firstChild.appendChild(newParagraph);

                            const setText = descriptionBoxRef.current.firstChild.innerHTML;
                            setDescription(setText);
                            i++;
                        } else {
                            clearInterval(intervalId);
                            updatedDocContent.description = descriptionBoxRef.current.firstChild.innerHTML;
                            setDocContent(updatedDocContent);
                            setDocSave(true)

                        }
                    }, speed);
                }
            };

            typeContent(newContent, 2);

        } catch (err) {
            console.error("Fetching data failed:", err);
        }



    };





    const [cursor, setCursor] = useState({})
    const getCursorPositionInfo = () => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const { startOffset } = range;
            const parentElement = range.commonAncestorContainer;
            const tagName = parentElement.nodeType === Node.TEXT_NODE
                ? parentElement.parentNode.tagName
                : parentElement.tagName;
            const closestElement = parentElement.nodeType === Node.TEXT_NODE
                ? parentElement.parentNode
                : parentElement;

            const elementIndex = Array.from(closestElement.parentNode.children).indexOf(closestElement);
            const cursorInfo = {
                tagName: tagName,
                offset: startOffset,
                paragraphIndex: elementIndex
            };
            setCursor(cursorInfo);

            const paragraphRange = document.createRange();
            paragraphRange.selectNodeContents(parentElement);
            paragraphRange.collapse(false);

            const rect = paragraphRange.getBoundingClientRect();
            setCaretPosition({
                x: rect.left,
                y: rect.top + window.scrollY
            });

            setContinueWriting(true);
            setTagName(cursorInfo.tagName.toLowerCase())

        }

        return null;
    };

    const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

    const [keywordPresent, setKeywordPresent] = useState([])
    useEffect(() => {

        const foundKeywords = secondaryKeywords
            .filter(keyword =>
                title.toLowerCase().includes(keyword.value.toLowerCase()) || description.toLowerCase().includes(keyword.value.toLowerCase())
            )
            .map(keyword => keyword.value.toLowerCase());
        setKeywordPresent(foundKeywords);

    }, [title, description]);
    const [continueWriting, setContinueWriting] = useState(false);
    const [caretPosition, setCaretPosition] = useState({ x: 0, y: 0 });

    const handleSuggest = () => {
        setDisplay(false)
    }


    return (
        <>
            <div className="Container">
                <div className="left" onDrop={handleDrop} onDragOver={handleDragOver}>
                    <div className="left-pading">
                        <div className="copyImg" onClick={() => copyToClipboard(title, description)}>
                            <img className="imgCopy" src={copyIcon} alt="Copy" />
                        </div>

                        {metaDescription ? (<>
                            <h4 className="hdngMetaDscp">
                                Meta Description
                            </h4>
                            <input className="paraMetaDscp" value={metaDescription} onChange={HandleMetaDescription} />
                        </>
                        ) : (
                            <div
                                className="flexMeta"
                                onClick={handleClick}
                                style={{ cursor: isDisabled ? 'not-allowed' : 'pointer', opacity: isDisabled ? 0.5 : 1 }}
                            >
                                <img className="Imageadd" src={addIcon} alt="Add" />
                                <div className="metaText">
                                    CREATE META DESCRIPTION
                                </div>
                            </div>
                        )}
                        <div className="add-content-scroll">
                            <div ref={titleBoxRef} className={`titleBox ${title ? 'no-border' : 'border'}`} onClick={handleSuggest}>

                                <ContentEditable
                                    className="edit-able"
                                    html={title}
                                    onChange={handleTitle}
                                    placeholder={"TITLE"}


                                />

                            </div>
                            <div className="makebtn-hover">
                                <div ref={descriptionBoxRef} className={`textBox ${description ? 'no-border text-area-max' : 'border text-area'} ${title ? '' : 'disabled-description'}`}>

                                    <ContentEditable
                                        className="edit-able"
                                        html={description}
                                        onChange={handleEdit}
                                        onMouseUp={getCursorPositionInfo}
                                        placeholder={"START WRITING"}
                                        disabled={!title}


                                    />


                                    {continueWriting && (
                                        <>
                                            <div
                                                className="imgArrowHover"
                                                onClick={HandleSet}
                                                style={{
                                                    position: 'absolute',
                                                    left: caretPosition.x,
                                                    top: caretPosition.y,
                                                }}
                                            >
                                                <img
                                                    src={continue_arrow}
                                                    alt=""
                                                    style={{
                                                        width: headingTags.includes(tagName) ? '46x' : '28px',
                                                        height: headingTags.includes(tagName) ? '46px' : '28px'  // Set both width and height based on heading
                                                    }}
                                                />                                            <span
                                                    className="textHoverArrow"
                                                    style={{
                                                        left: -25,
                                                        top: -24,
                                                    }}

                                                >
                                                    Continue Writing
                                                </span>
                                            </div>
                                        </>
                                    )}


                                </div>
                                {!description && (
                                    <div className="flexbtn-writemore">
                                        <div className="button cursor-pointer" onClick={HandleSet} >
                                            Write more
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <footer className="Fotter">
                        <div className="flexFotter">

                            <div className={`button ${!description ? 'disabled-description' : ''}`}
                                onClick={title.trim() && description.trim() ? HandleSet : null}>Write more</div>
                            <div className="words">{countWords(title + description)} words</div>
                        </div>
                    </footer>
                </div>
                <div className="right">
                    <Builder seoScore={seoScore} bar={bar} />
                    <div className="mid">
                        <div className="midFlex">
                            {['Element', 'Keyword', 'SERP'].map((tab) => (
                                <div
                                    key={tab}
                                    className={`${activeTab === tab ? 'Active' : 'div-2-builder'}`}
                                    onClick={() => handleTabChange(tab)}
                                >
                                    {tab}
                                </div>
                            ))}
                        </div>
                    </div>
                    {display && subheading && intro && faq && keyPoint && summary && image && activeContent === "Element" && (
                        <div>
                            <DocumentSection handleDisplay={handleDisplay} handleSubheading={handleSubheading}
                                handleIntro={handleIntro} handleImage={handleImage} handleKeyPoint={handleKeyPoint} handleSummary={handleSummary} handleFaq={handleFaq} title={title} />
                        </div>
                    )}
                    {!display && subheading && intro && activeContent === "Element" && (
                        <>
                            <div className="createTitle">
                                <div className="createTitleFlex">
                                    <div className="createArrow">
                                        <img src={chevronLeft} alt="Back" onClick={handleDisplay} />
                                    </div>
                                    <div className="createTitleText">Create Title</div>
                                </div>
                            </div>
                            <div className="element-section" ref={elementSectionRef}>
                                {titles && titles.length > 0 && (
                                    titles.map((title) => (
                                        <div
                                            key={title.id}
                                            className="titlePadding"

                                        >
                                            <div className="titleBorder"
                                                onDragStart={handleDragStart(title, 'title')}
                                                draggable>
                                                <div className="titleImageFlex">
                                                    <img className="titleImage" src={dragVertical} alt="Drag" />
                                                    <div className="titleParagraph">
                                                        {title.text.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>{line}<br /></React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="titleCount">{title.count}</div>
                                            </div>
                                        </div>
                                    )))}

                                {loader && (
                                    <>
                                        <div className="centered-loader">
                                            <div className="loader transparent">
                                                <div className="circle-loader">
                                                    {[...Array(8)].map((_, index) => (
                                                        <span key={index} className="dot"></span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>


                            <div className='gen-more'>
                                <div className="newButtonFoter" onClick={generateTitles}>
                                    Generate more
                                </div>
                            </div>
                        </>
                    )}

                    {display && !subheading && intro && activeContent === "Element" && (
                        <>
                            <div className="createTitle">
                                <div className="createTitleFlex">
                                    <div className="createArrow">
                                        <img src={chevronLeft} alt="Back" onClick={handleSubheading} />
                                    </div>
                                    <div className="createTitleText">Create Subheading</div>
                                </div>
                            </div>
                            <div className={`element-section ${noSubHeading ? "centered-element" : ""}`} ref={elementSectionRef}>
                                {!noSubHeading && subHeadings && subHeadings.length > 0 && (
                                    subHeadings.map((subheading) => {
                                        return (
                                            <div key={subheading.id}
                                                className="titlePadding"

                                            >
                                                <div className="titleBorder"
                                                    onDragStart={handleDragStart(subheading, 'subheading')}
                                                    draggable
                                                >
                                                    <div className="titleImageFlex">
                                                        <img className="titleImage" src={dragVertical} alt="Drag" />
                                                        <div className="titleParagraph">
                                                            {subheading.text.split('\n').map((line, index) => (
                                                                <React.Fragment key={index}>
                                                                    {line}<br />
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="titleCount">{subheading.headingLevel}</div>
                                                </div>
                                            </div>
                                        );
                                    }))}
                                {noSubHeading &&
                                    <h4>No subheading found</h4>}

                                {loader && (
                                    <>
                                        <div className="centered-loader">
                                            <div className="loader transparent">
                                                <div className="circle-loader">
                                                    {[...Array(8)].map((_, index) => (
                                                        <span key={index} className="dot"></span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='gen-more'>
                                <div className="newButtonFoter" onClick={generateSubheading}>
                                    Generate more
                                </div>
                            </div>
                        </>
                    )}

                    {display && subheading && !intro && activeContent === "Element" && (
                        <>
                            <div className="createTitle">
                                <div className="createTitleFlex">
                                    <div className="createArrow">
                                        <img src={chevronLeft} alt="Back" onClick={handleIntro} />
                                    </div>
                                    <div className="createTitleText">Create Introduction</div>
                                </div>
                            </div>
                            <div className={`element-section ${noIntros ? "centered-element" : ""}`} ref={elementSectionRef}>
                                {!noIntros && intros && intros.length > 0 && (
                                    intros.map((intro) => (
                                        <div
                                            key={intro.id}
                                            className="titlePadding"

                                        >
                                            <div className="titleBorder"
                                                onDragStart={handleDragStart(intro, 'intro')}
                                                draggable>
                                                <div className="titleImageFlex">
                                                    <img className="titleImage" src={dragVertical} alt="Drag" />
                                                    <div className="titleParagraph">
                                                        {intro.text.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>{line}<br /></React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )))}
                                {noIntros &&
                                    <h4>No Introducations found</h4>}
                                {loader && (
                                    <>
                                        <div className="centered-loader">
                                            <div className="loader transparent">
                                                <div className="circle-loader">
                                                    {[...Array(8)].map((_, index) => (
                                                        <span key={index} className="dot"></span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='gen-more'>
                                <div className="newButtonFoter" onClick={generateIntros}>
                                    Generate more
                                </div>
                            </div>
                        </>

                    )}

                    {display && subheading && intro && !faq && activeContent === "Element" && (
                        <>
                            <div className="createTitle">
                                <div className="createTitleFlex">
                                    <div className="createArrow">
                                        <img src={chevronLeft} alt="Back" onClick={handleFaq} />
                                    </div>
                                    <div className="createTitleText">Create FAQ</div>
                                </div>
                            </div>
                            <div className={`element-section ${noFaqs ? "centered-element" : ""}`} ref={elementSectionRef}>
                                {!noFaqs && faqs && faqs.length > 0 && (
                                    faqs.map((faq) => (
                                        <div
                                            key={faq.id}
                                            className="titlePadding"

                                        >
                                            <div className="titleBorder"
                                                onDragStart={handleDragStart(faq, 'faq')}
                                                draggable>
                                                <div className="titleImageFlex">
                                                    <img className="titleImage" src={dragVertical} alt="Drag" />
                                                    <div className="titleParagraph">
                                                        {/* Display the question */}
                                                        <strong>{faq.question.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>
                                                                {line}<br />
                                                            </React.Fragment>
                                                        ))}</strong>
                                                        {/* Display the answer */}
                                                        <p>{faq.answer.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>
                                                                {line}<br />
                                                            </React.Fragment>
                                                        ))}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )))}
                                {noFaqs &&
                                    <h4>No FAQ's found</h4>}
                                {loader && (
                                    <>
                                        <div className="centered-loader">
                                            <div className="loader transparent">
                                                <div className="circle-loader">
                                                    {[...Array(8)].map((_, index) => (
                                                        <span key={index} className="dot"></span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='gen-more'>
                                <div className="newButtonFoter" onClick={generateFaqs}>
                                    Generate more
                                </div>
                            </div>
                        </>
                    )}

                    {display && subheading && intro && faq && !keyPoint && activeContent === "Element" && (
                        <>
                            <div className="createTitle">
                                <div className="createTitleFlex">
                                    <div className="createArrow">
                                        <img src={chevronLeft} alt="Back" onClick={handleKeyPoint} />
                                    </div>
                                    <div className="createTitleText">Create KeyPoints</div>
                                </div>
                            </div>
                            <div className={`element-section ${nokeyPoints ? "centered-element" : ""}`} ref={elementSectionRef}>
                                {!nokeyPoints && keyPoints && keyPoints.length > 0 && (
                                    keyPoints.map((keypoint) => (
                                        <div
                                            key={keypoint.id}
                                            className="titlePadding"

                                        >
                                            <div className="titleBorder"
                                                onDragStart={handleDragStart(keypoint, 'keypoint')}
                                                draggable>
                                                <div className="titleImageFlex">
                                                    <img className="titleImage" src={dragVertical} alt="Drag" />
                                                    <div className="titleParagraph">
                                                        <strong>•  {keypoint.title.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>
                                                                {line}<br />
                                                            </React.Fragment>
                                                        ))}</strong>
                                                        <p>{keypoint.description.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>
                                                                {line}<br />
                                                            </React.Fragment>
                                                        ))}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                                {nokeyPoints &&
                                    <h4>No Keypoints  found</h4>}
                                {loader && (
                                    <>
                                        <div className="centered-loader">
                                            <div className="loader transparent">
                                                <div className="circle-loader">
                                                    {[...Array(8)].map((_, index) => (
                                                        <span key={index} className="dot"></span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='gen-more'>
                                <div className="newButtonFoter" onClick={generateKeypoints}>
                                    Generate more
                                </div>
                            </div>
                        </>

                    )}


                    {display && subheading && intro && faq && keyPoint && !summary && activeContent === "Element" && (
                        <>
                            <div className="createTitle">
                                <div className="createTitleFlex">
                                    <div className="createArrow">
                                        <img src={chevronLeft} alt="Back" onClick={handleSummary} />
                                    </div>
                                    <div className="createTitleText">Create Summary</div>
                                </div>
                            </div>
                            <div className={`element-section ${noSummary ? "centered-element" : ""}`} ref={elementSectionRef}>
                                {!noSummary && summarys && summarys.length > 0 && (
                                    summarys.map((summary, index) => (
                                        <div
                                            key={index}
                                            className="titlePadding"

                                        >
                                            <div className="titleBorder"
                                                onDragStart={handleDragStart(summary, 'summary')}
                                                draggable>
                                                <div className="titleImageFlex">
                                                    <img className="titleImage" src={dragVertical} alt="Drag" />
                                                    {summary}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}

                                {noSummary &&
                                    <h4>No Summaries  found</h4>}
                                {loader && (
                                    <>
                                        <div className="centered-loader">
                                            <div className="loader transparent">
                                                <div className="circle-loader">
                                                    {[...Array(8)].map((_, index) => (
                                                        <span key={index} className="dot"></span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='gen-more'>
                                <div className="newButtonFoter" onClick={generateSummary}>
                                    Generate more
                                </div>
                            </div>
                        </>

                    )}



                    {display && subheading && intro && faq && keyPoint && summary && !image && activeContent === "Element" && (
                        <>

                            <div className="createTitle">
                                <div className="createTitleFlex">
                                    <div className="createArrow">
                                        <img src={chevronLeft} alt="Back" onClick={handleImage} />
                                    </div>
                                    <div className="createTitleText">Create Image</div>
                                </div>
                            </div>
                            <div>
                                <div className="ImagesHidingPadng">
                                    {/* <div className="ImagesHiding">
                                        <div
                                            className={`${activeImagesTab === 'stock' ? 'active-images' : 'images-tab'}`}
                                            onClick={() => handleTabClick('stock')}
                                        >
                                            Stock images
                                        </div>
                                        <div
                                            className={`${activeImagesTab === 'ai' ? 'active-images' : 'images-tab'}`}
                                            onClick={() => handleTabClick('ai')}
                                        >
                                            AI images
                                        </div>
                                    </div> */}

                                    {activeImagesTab === "stock" &&
                                        <div className="ImageDownload">
                                            {Array.isArray(images) && images.length > 0 ? (
                                                images.map((image, index) => (
                                                    <div className="bgImgOfStock"
                                                        key={index}
                                                        onDragStart={handleDragStart(image, 'image')}
                                                        draggable
                                                    >
                                                        <img src={image.url} alt="" className="st-images" />
                                                        <div className="Doteimg">
                                                            <img src={dot} alt="" />
                                                        </div>
                                                        <div className="download-image">
                                                            <a href={image.url} download="image2.jpg">
                                                                <img src={download} alt="" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div>No images available</div>  // You can customize this part as needed
                                            )}
                                        </div>
                                    }
                                    {activeImagesTab === "ai" &&
                                        <></>
                                    }
                                </div>
                            </div>
                        </>

                    )}

                    {activeContent === "Keyword" && (
                        <div className="element-section-keyword">
                            <div className="addKeywordText" onClick={handleOpen}>+ ADD KEYWORD</div>
                            <div className="hidingsOfaddKeyword">
                                <div className="hiding1Keyword">Keyword</div>
                                <div className="hiding2KD">KD</div>
                                <div className="hiding3Volume">Volume</div>
                            </div>
                            <div className="keyword-list">
                                {secondaryKeywords.map((keyword, index) => (
                                    <KeywordsListCard
                                        key={index}
                                        name={keyword.label}
                                        kd={keyword.kd}
                                        vol={keyword.volume}
                                        onSelect={handleKeywordSelect}
                                        isSelected={documentKeywords.includes(keyword.label)}
                                        keywordPresent={keywordPresent}
                                    />
                                ))}
                            </div>
                        </div>
                    )}


                    {activeContent === "SERP" && (
                        <>
                            <div className="element-section">
                                {serpList?.data?.length > 0 ? (
                                    serpList.data.map((item) => {
                                        const [id, serpData] = Object.entries(item)[0];
                                        return (
                                            <SerpCard
                                                key={id}
                                                id={id}
                                                title={serpData.title}
                                                url={serpData.url}
                                                wordCount={serpData.word_count}
                                                outboundLinksCount={serpData.outbound_links_count}
                                                keywordsCount={serpData.keywords_count}
                                                titleScore={serpData.Title_score}
                                                seoScore={serpData.SEO_score}
                                            />
                                        );
                                    })
                                ) : (
                                    <div>No SERP data available</div>
                                )}
                            </div>

                            {serpList?.data?.length > 0 && (
                                <div className="viewAllbtnpdng" onClick={handleSerpModal}>
                                    <div className="viewAllbtn">
                                        View all
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div >

            <KeywordModal openKeword={addKeyword} setOpenKeyword={setAddKeyword} keywordOptions={secondaryKeywords} documentKeywords={documentKeywords} setDocumentKeywords={setDocumentKeywords} isBuilder={true} />
            <SerpModal open={openSerpModal} serpList={serpList} setOpenSerpModal={setOpenSerpModal} />
        </>
    );
}

export default DocumentDetailContent;
