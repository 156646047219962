import vector from "../../assets/vector.svg";
import React, { useEffect, useState } from "react";
import left from "../../assets/Chevron_left.svg";
import downWhite from "../../assets/Chevron_Down_White.svg";
import edit from "../../assets/fi_9356210.svg";

import "../Documents/documentDetail.css"
import DocEdit from "./DocEdit";
import { Link, useNavigate } from "react-router-dom";
import SaveModal from "./SaveModal";
import api from "../../utils/api";
import useStore from "../../Store";
import { toast } from "react-toastify";
import { countWords } from "../../utils/utils";


const DocumentNav = ({ name, id, projectId, toneValue, target, docStatus, docData }) => {
    const [docEditModal, setDocEditModal] = useState(false);
    const [docName, setDocName] = useState(name)
    const [openSaveModal, setOpenSaveModal] = useState(false)
    const [open] = useState(false)
    const [tone, setTone] = useState([])
    const [savedDocStatus, SetSaveDocStatus] = useState([])
    const [savedDocContent, SetSaveDocContent] = useState([])
    const [selectedTone, SetSelectedTone] = useState("Tone of Voice")
    const { docContent, DocSave, setDocSave,clearDocContent} = useStore();
    const navigate = useNavigate();

    const handleEdit = (item) => {

        setDocEditModal(true)
    }
    useEffect(() => {
        const tones = async () => {
            try {

                const response = await api.get(
                    "/tones/",

                );

                setTone(response.data)



            } catch (err) {
                console.error("Error getting tones. Please try again.");
            }
        };

        setDocSave(false)
        tones();
    }, []);

    const areEqual = JSON.stringify(savedDocContent) === JSON.stringify(docContent);

    const saveModal = () => {
        setOpenSaveModal(true)
    }
    useEffect(() => {
        setDocName(name)
    }, [name])


    useEffect(() => {
        SetSelectedTone(toneValue)
    }, [toneValue])


    useEffect(() => {
        SetSaveDocContent(docData)
    }, [docData])

    useEffect(() => {
        SetSaveDocStatus(docStatus)
    }, [docStatus])

    const showModal = (event) => {
        event.preventDefault();
        event.returnValue = '';
        return;
    }

    useEffect(() => {
        return () => {
            if (DocSave) {
                saveDoc()
            }
        };
    }, [DocSave]);


    const handleSave = (event) => {
        event.preventDefault()
            navigate(-1);
    }

    const saveDoc = async () => {
    
        const wordCount = countWords(docContent.title + docContent.description)
        const imgCount = (docContent.description?.match(/<img [^>]*>/g) || []).length;
       
        try {
            await api.patch(
                `/documents/${id}/`, {
                document_content: docContent, words_count: wordCount, images_count: imgCount,

            }
            );
        } catch (err) {
            toast.error("Error updating Document. Please try again.");
        }
    }

    return (
        <>
            <div className="navBar">
                <div className="navLeft">
                    <div className="backArrow">
                        <Link onClick={handleSave} > <img src={left} alt="Back" /> </Link>
                    </div>
                    <div className="newDocText">
                        {docName}
                    </div>
                    <div className="editButton">
                        <img src={edit} alt="Edit" onClick={handleEdit} />
                    </div>
                </div>
                <div className="navRight ">
                    <div className="div-2 disabled-description " >
                        <strong>{target || "Target Audience"}</strong>
                        <img className="div-2-img-1 not-allowed" src={vector} alt="" />
                    </div>
                    <div className="toneFlex disabled-description">
                        <div className="toneText">
                            {selectedTone}
                        </div>
                        <img className="div-2-img-1" src={vector} alt="" />
                        {open &&
                            <div className="tone-dropdown">
                                <div className="dropdown-div1">
                                    <div className="dropdown-h1">Tone of Voice</div>
                                    <div className="dropdown-p1">
                                        Defining the correct tone of voice enhances AI's ability to <br />
                                        generate superior content.
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* <div className="saveFlex" onClick={saveModal}>
                        <div className="saveText" >
                            Save
                        </div>
                    </div> */}
                </div>
            </div>

            <DocEdit open={docEditModal} setIsModalOpen={setDocEditModal} id={id} docName={docName} setDocName={setDocName} />
            <SaveModal open={openSaveModal} setOpenSaveModal={setOpenSaveModal} docName={docName} setDocName={setDocName} id={id} status={savedDocStatus} />
        </>
    );
};

export default DocumentNav;
